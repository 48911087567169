import Department from './Department.vue'
import DepartmentCreate from './Create.vue'
import DepartmentEdit from './Create.vue'

export default [
    {
        path: '/department',
        name: 'Department',
        component: Department
    },
    {
        path: '/department/create',
        name: 'DepartmentCreate',
        component: DepartmentCreate
    },
    {
        path: '/department/edit/:id',
        name: 'DepartmentEdit',
        component: DepartmentEdit
    },
]
